<template>
    <div class="mainbox">
        <div class="detail_left detail_lefts">
            <div class="d_til">学校新闻</div>
            <div @click="goDetail(item)" v-for="(item, index ) in mList" :key="index"
                :class="search.projectCategoryId == item.id ? 'd_tils d_active' : 'd_tils'">
                <div v-if="search.projectCategoryId == item.id" class="line"></div>
                {{ item.typeName }}
            </div>
        </div>
        <div class="detail_left"></div>

        <div class="detail_right">
            <div class="detail_right_til">{{ itemDetail.typeName }}学校新闻</div>
            <div class="question_list">
                <div @click="goNext(`/gxGather/abroad/newsDetail?id=${item.id}`)" v-for="(item, index) in qusetionList"
                    :key="index" class="question_item">
                    <div class="q_detail_img">
                        <img :src="item.newCover" alt="">
                    </div>
                    <div class="q_detail">
                        <div>
                            <div class="q_detail_til elp">{{ item.newTitle }}</div>
                            <div v-html="ImgSizeLimitclear(item.newDetail)" class="q_detail_tils threeEllipsis"></div>
                        </div>

                        <div class="q_detail_tils">{{ item.updateTime }}</div>
                    </div>
                </div>
                <el-empty v-if="!qusetionList || qusetionList.length == 0" description="暂无数据"></el-empty>

            </div>
            <el-pagination background style="display: flex;justify-content: center;" :page-count="4"
                class="pagination partner-pagination" :current-page="search.pageNum" :page-size="search.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange"
                @size-change="handleSizeChange" :page-sizes="[10, 20, 50, 100]" />
        </div>
    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
    components: {},
    data() {
        return {
            search: {
                pageSize: 10,
                pageNum: 1,
                projectTypeId: '',
                projectCategoryId: ''
            },
            itemDetail: {},
            total: 0,
            mList: [],
            qusetionList: [],
        };
    },
    created() {
        this.search.projectCategoryId = localStorage.getItem('projectCategoryId');
        if (localStorage.getItem('projectCategoryId')) {

        } else {
            if (this.$route.query.id && this.$route.query.id != 'undefined' && this.$route.query.id != '') {
                this.search.projectCategoryId = this.$route.query.id
            }
        }
        // if (this.$route.query.id && this.$route.query.id != 'undefined' && this.$route.query.id != '') {
        //     this.search.projectCategoryId = this.$route.query.id
        // }
        this.search.projectTypeId = localStorage.getItem('projectTypeId');

        know.selectProjectTypePullList({ parentId: this.search.projectTypeId }).then((res) => {
            this.mList = res.data;
        });
        this.getList()
    },
    mounted() {

    },
    methods: {
        getList() {
            know.getContentNewPcList({ ...this.search }).then((res) => {
                this.qusetionList = res.rows
                this.total = res.total
            });
        },
        handleSizeChange(val) {
            this.search.pageNum = 1
            this.search.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.search.pageNum = val
            this.getList()
        },
        goDetail(item) {
            this.search.pageNum = 1
            this.itemDetail = item
            this.search.projectCategoryId = item.id
            this.getList()

        }

    },
};
</script>

<style lang="less" scoped>
.mainbox {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;

    .detail_left {
        width: 236px;
        border-radius: 6px 6px 6px 6px;
        background: #fff;
        padding-bottom: 20px;
        height: fit-content;

        .d_til {
            height: 43px;
            font-family: PingFangSC-Semibold, PingFangSC-Semibold;
            font-weight: normal;
            font-size: 18px;
            color: #FFFFFF;
            background: url('~@/assets/img/TechnicalCollege/9.png');
            background-size: 100% 100%;

            display: flex;
            align-items: center;
            justify-content: center;
        }


        .d_tils {
            position: relative;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: normal;
            font-size: 16px;
            color: #666666;
            padding-left: 40px;

            height: 37px;
            line-height: 37px;
            border-radius: 0px 0px 0px 0px;
            margin-top: 10px;
            cursor: pointer;
        }

        .d_active {
            color: #BC3535;
            background: #FEF3F3;
        }

        .line {
            position: absolute;
            width: 4px;
            height: 37px;
            background: #DF3C2F;
            border-radius: 0px 0px 0px 0px;
            left: 0;
        }

    }

    .detail_lefts {
        position: fixed;
    }

    .detail_right {
        width: 943px;
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;

        .detail_right_til {
            height: 70px;
            text-align: center;
            line-height: 70px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: normal;
            font-size: 20px;
            color: #444444;
            font-weight: bold;
            border-bottom: 1px solid #DFDFDF;
        }

        .question_list {
            padding: 30px;
            padding-top: 0;

            .question_item {
                margin-top: 25px;
                display: flex;

                cursor: pointer;

                .q_detail_img {
                    width: 220px;
                    height: 160px;
                    background: #D8D8D8;
                    border-radius: 4px 4px 4px 4px;
                    flex-shrink: 0;
                    overflow: hidden;
                }

                .q_detail {
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-top: 10px;

                    .q_detail_til {
                        width: 600px;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: bold;
                        font-size: 16px;
                        color: #333333;
                    }

                    .q_detail_tils {
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        font-size: 14px;
                        color: #767676;
                        margin-top: 18px;
                    }
                }


            }
        }
    }
}

img {
    display: block;
    width: 100%;
    height: 100%;
}
</style>